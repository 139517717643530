<template>
  <div>
    <!-- <h1>1</h1> -->
    <van-popup v-model:show="show" position="bottom" @close="confirm">
      <van-area
        ref="area"
        title="请选择地址"
        :value="model"
        :area-list="areaList"
        @confirm="confirm"
        @cancel="cancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { Area, Popup } from 'vant'
export default {
  name: 'Address',
  components: {
    [Area.name]: Area,
    [Popup.name]: Popup,
  },
  props: {
    county: Number,
  },
  data() {
    return {
      show: true,
      model: 0,
      areaList: {},
    }
  },
  created() {
    const province_list = {}
    const city_list = {}
    const county_list = {}
    const data = require('@/static/city.json')
    for (const item of data) {
      province_list[item.code] = item.name
      for (const item1 of item.cityList) {
        city_list[item1.code] = item1.name
        for (const item2 of item1.areaList) {
          county_list[item2.code] = item2.name
        }
      }
    }
    this.areaList.province_list = province_list
    this.areaList.city_list = city_list
    this.areaList.county_list = county_list
    if (this.county !== '') {
      this.model = this.county + ''
    }
  },
  methods: {
    confirm(res) {
      this.$emit('confirm', res)
    },
    cancel() {
      this.$emit('confirm', undefined)
    },
  },
}
</script>
